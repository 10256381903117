.billing-page {
    background-color: #e4e4e4;
    background-size: cover;
    padding: 60px 15px;
    display: block;
    box-sizing: content-box;
    min-height: 100vh;
}

button.btn-copy {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 10pt;
    padding: 0 15px;
    border: 2px solid #0a0a23;
    background-color: #fff;
    color: #0a0a23;
    border-radius:15px;
    transition: .2s;
}

button.btn-copy:hover {
    background-color: #0a0a23;
    color: #fff;
}

button.btn-copy.copied {
    background-color: #0a0a23;
    color: #fff;
}

.box-center {
    position: relative;
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
    padding: 15px 15px;
    border-radius: 10px;
    box-shadow: 0px 8px 20px 0px rgb(0 0 0 / 15%);
    background: #fff;
    overflow: hidden;
}

.payment-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment-page-header h4 {
    display: block;
    justify-content: center;
}

.payment-page-header img {
    margin: 20px;
    border-radius: 50%;
    width: 60px;
}

.payment-list {
    padding: 0px 0 10px 0;
}

.payment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 15px 0;
    border-radius: 5px;
    border: 1px solid #dedede;
    transition: .2s ;
    min-height: 65px;
}

.payment-item img {
    width: 100px;
}

.payment-item .bank-name {
    font-weight: 500;
    text-align: right;
}

.payment-item .payment-fee {
    font-size: 10pt;
    font-weight: 400;
}

.payment-item:hover,
.payment-item:active {
    border: 1px solid #0583d2;
    cursor: pointer;
    box-shadow: 2px 2px 14px 0 #00000030;
}

.payment-item.unavailable {
    filter: grayscale(1);
    opacity: 0.5;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading .loading-text {
    font-size: 14pt;
    font-weight: 700;
}

.alert-payment {
    background-color: #D3DEDC;
    padding: 20px 15px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 30px;
}

.alert-payment span {
    display: block;
}

.alert-payment .note-payment {
    font-size: 13pt;
    margin-bottom: 10px;
}

.alert-payment .payment-date {
    font-weight: 700;
    font-size: 18pt;
}

.payment-account-info {
    margin: 20px 0;
    padding: 20px 0;
    border-bottom: 1px solid #dedede;
}

.payment-account-info .title {
    display: block;
    font-size: 14pt;
    margin-bottom: 10px;
}

.payment-account-info .bank-info,
.payment-account-info .amount-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-account-info .bank-info span.number,
.payment-account-info .amount-info span.number {
    font-size: 16pt;
    font-weight: bold;
}

.payment-account-info .bank-info img {
    width: 150px;
}

.payment-account-info .bank-info .virtul-acc-info span {
    display: block;
    text-align: center;
}

.payment-disclaimer ol {
    padding: 0;
    margin: 0;
    padding-left: 20px;
}

.billing-info {
    display: block;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #dedede;
}
.billing-info table {
    width: 100%;
}

.billing-info table tbody tr td:first-child {
    width: 100px;
}


@media (max-width: 575.98px) { 
    .payment-item .bank-name {
        font-size: 11pt;
    }

    .payment-account-info .bank-info {
        flex-direction: column;
    }
    
    .payment-account-info .bank-info img {
        margin-bottom: 15px;
    }

    .payment-account-info .bank-info .virtul-acc-info {
        margin-bottom: 15px;
    }
}

.payment-method-item {
    padding: 20px 10px;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #dedede;
    transition: .2s ;
}

.payment-method-item img {
    width: 100px;
    height: 50px;
    margin-bottom: 15px;
}

.payment-method-item:hover,
.payment-method-item:active {
    border: 1px solid #0583d2;
    cursor: pointer;
    box-shadow: 2px 2px 14px 0 #00000030;
}

.payment-method-item .payment-method-name {
    font-weight: 500;
}

.payment-qris-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment-qris-info .qris-wrapper {
    min-height: 354px;
    min-width: 200px;
    background-color: #e8e8e8;
    background-image: url('../images/loading.svg');
    background-position: center center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
}